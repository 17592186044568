//Components
import Layout from '../../hocs/Layout';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { RelatedCard } from '../../components/cards/RelatedCard';
import { Link } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { Navigate } from 'react-router';
import Stars from '../../components/products/Stars';
import StarRating from '../../components/products/StarRating';
import ProductImages from '../../components/products/ProductImages';
//Redux
import { connect } from 'react-redux';
import { get_product, get_related_products } from '../../redux/actions/products';
import { get_items, get_total, get_item_total, add_item } from '../../redux/actions/cart';
import { add_wishlist_item, get_wishlist_items, get_wishlist_item_total, remove_wishlist_item } from '../../redux/actions/wishlist';
import { get_reviews, get_review, create_review, update_review, delete_review, filter_reviews } from '../../redux/actions/reviews';
//Styles
import '../../styles/products.css';
//Icons
import { FaXTwitter } from 'react-icons/fa6';
import { FaFacebook, FaInstagram, FaChevronDown, FaPinterest, FaWhatsapp, FaEnvelope, FaChevronUp } from 'react-icons/fa';
import { FavoriteHeart } from '../../components/aditional/FavoriteHeart';


const ProductDetail = ({
	isAuthenticated,
	get_product,
	get_related_products,
	product,
	related_products,
	get_items,
	get_total,
	get_item_total,
	add_item,
	items,
	add_wishlist_item,
	get_wishlist_items,
	get_wishlist_item_total,
	remove_wishlist_item,
	get_reviews,
	get_review,
	create_review,
	update_review,
	delete_review,
	filter_reviews,
	review,
	reviews,
}) => {

	const params = useParams();
	const productId = params.productId;
	const currentUrl = window.location.href;
	const [descriptionVisible, setDescriptionVisible] = useState(false);
	const [characteristicsVisible, setCharacteristicsVisible] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [redirectToCart, setRedirectToCart] = useState(false);
	const [favoriteProduct, setFavoriteProduct] = useState(false);
	const [material, setMaterial] = useState(1);
	const [images, setImages] = useState({
			main_photo: '',
			secundary_photo: ''
	})
	const [formData, setFormData] = useState({
	    comment:'',
	    rating:'',
    })

    const { comment,rating } = formData

    useEffect(() => {
		if (product && product.material) {
	      setMaterial(stepMaterial[product.material]);
	    }
	}, [product]);

	const materialMap = {
		oro: 'Oro',
		plata_900: 'Plata 900',
		plata_925: 'Plata 925',
		plata_950: 'Plata 950',
		plata_oro: 'Plata y Oro',
		acero_quirurgico: 'Acero Quirúrgico',
		acero_dorado: 'Acero Dorado',
		acero_blanco: 'Acero Blanco',
		silicona: 'Silicona',
		cuero: 'Cuero',
		metal: 'Metal',
		caucho: 'Caucho',
		otro: 'Otro'
	};

	const stepMaterial = {
		oro: 0,
		plata_900: 1,
		plata_925: 2,
		plata_950: 3,
		plata_oro: 4,
		acero_quirurgico: 5,
		acero_dorado: 6,
		acero_blanco: 7,
		silicona: 8,
		cuero: 9,
		metal: 10,
		caucho: 11,
		otro: 12
	};


    const addToCart = async () => {
		if (product && product !== null && product !== undefined && product.quantity > 0) {
			setLoading(true);
			await add_item(product);
			await get_items();
			await get_total();
			await get_item_total();
			setLoading(false);
		}
	}

	const addToWishlist = async () => {
		if (!isAuthenticated) {
			return <Navigate to='/login' />
		} else if (
			product && 
			product !== null &&
			product !== undefined
		) {
			await add_wishlist_item(product.id);
			await get_wishlist_items();
			await get_wishlist_item_total();
		}
	}

	const removeItem = async () => {
		await remove_wishlist_item(product.id);
	}

	const handleMouseOver = (imageUrl) => {
		setSelectedImage(imageUrl);
	};

	useEffect(() => {
		if (productId && items) {
	  		const productIds = items.map(item => item.product.id.toString());
	  		const productIdString = productId.toString();
       		if (productIds.includes(productIdString)) {
        		setRedirectToCart(true);
        	}
		}
	}, [productId, items])

	useEffect(() => {
		window.scrollTo(0,0)
		get_product(productId);
		get_related_products(productId);
		get_wishlist_items();
		get_wishlist_item_total();
	}, []);

	useEffect(() => {
		if (product && product.main_photo) {
			setImages({
            	main_photo: product.main_photo,
            	secundary_photo: product.secundary_photo
        	});
		}
	}, [product]);

	useEffect(() => {
		get_reviews(productId);
		get_review(productId);
	}, [productId]);

	const toggleDescription = () => {
		setDescriptionVisible(!descriptionVisible);
	};

	const toggleCharacteristics = () => {
		setCharacteristicsVisible(!characteristicsVisible);
	};

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const leaveReview = e => {
      	e.preventDefault()
      	if (rating !== null)
        	create_review(productId, rating, comment);
    }
    
    const updateReview = e => {
      	e.preventDefault()
      	if (rating !== null)
        	update_review(productId, rating, comment);
    }

    const deleteReview = () => {
      	const fetchData = async () => {
          	await delete_review(productId);
          	await get_review(productId);
          	// setRating(5.0);
          	setFormData({
              	comment: ''
          	});
      	};
      	fetchData();
    };

    const filterReviews = numStars => {
        filter_reviews(productId, numStars);
    };

    const getReviews = () => {
        get_reviews(productId);
    };

	return (
		<Layout>
			<div className='container-banner-all detail-product'>
				<h3>Detalles</h3>
			</div>
			<div className='container-detail-products'>
				<div className='contain-product'>
					<ProductImages images={images && images} />
					<div className='container-product-info'>
						<h3 className='detail-title'>{product && product.name}</h3>
						<p className='detail-price'>$ {product && product.final_price}</p>
						<div>
							<Stars rating={product && product.average_rating} />
						</div>						
						<div className='container-detail-description'>
							<div className='toggle-description' onClick={toggleDescription}><p>Descripción</p>{descriptionVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
								{descriptionVisible && <p className='detail-description'>{product && product.description}</p>}
							</div>
							<div className='container-detail-description'>
								<div className='toggle-description' onClick={toggleCharacteristics}><p>Características</p>{characteristicsVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
									{characteristicsVisible && 
										<>
											{product.material && <p className='detail-details'>Material: <span>{product && materialMap[product.material]}</span></p>}
											{product.size &&<p className='detail-details'>Detalles: <span>{product && product.size}</span></p>}
										</>
									}
								</div>
								<div className='container-share-with'>
									<p>Compartir</p>
									<div className='social-share-with'>
										<Link to={`mailto:?subject=Check%20this%20out&body=Check%20out%20this%20product:%20${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaEnvelope className='icons-share' /></Link>
										<Link to={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaFacebook className='icons-share' /></Link>
										<Link to={`https://www.instagram.com/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaInstagram className='icons-share' /></Link>
										<Link to={`https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaWhatsapp className='icons-share' /></Link>
										<Link to={`https://pinterest.com/pin/create/button/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaPinterest className='icons-share' /></Link>
										<Link to={`https://twitter.com/intent/tweet?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaXTwitter className='icons-share' /></Link>
									</div>
								</div>
								<div className='container-quantity'>
									<p>Cantidad disponible: <span>{product && product.quantity}</span></p>
								</div>
								<div className='container-handle-product'>
									{
										product &&
										product !== null &&
										product !== undefined && 
										product.quantity > 0 ? (
											loading ? (
												<button type='button' className='add-cart-button'><Oval
											  		visible={true}
													height="20"
													width="20"
													color="#ddd"
													ariaLabel="oval-loading"
													wrapperStyle={{}}
													wrapperClass=""
													secundaryColor="#ddd"
												/>
												</button>
											) : (
												redirectToCart ? (
													<Link to='/cart' className='add-cart-button' style={{textDecoration: 'none'}}>Ver carrito</Link>
												):(
													<button className='add-cart-button' onClick={addToCart}>Añadir al carrito</button>
												)
											)
										) : (
											<p className='unstocked-detail'>Sin Stock</p>
										)
									}
									<FavoriteHeart className='favorite-icon' isItemFavorited={favoriteProduct} addItem={addToWishlist} deleteItem={removeItem} />
								</div>
							{isAuthenticated ? (
								review ? (
				            		<form onSubmit={e => updateReview(e)} className='container-form-reviews'>
					                	<div className='contain-msg-review'>
					                    	<label htmlFor="comment" className="">Actualizar reseña</label>
					                    	<div className="mt-1">
					                        	<textarea
													rows={4}
													name="comment"
													id="comment"
													required
													value={comment}
													onChange={e=>onChange(e)}
													placeholder={review.comment}
													className='review-msg-input'
													defaultValue={''}
												/>
											</div>
					               		</div>
										<StarRating onChange={onChange} rating={rating}/>
										<button type="submit" className='add-cart-button'>Actualizar</button>
									</form>
					        	) : ( 
									<form onSubmit={e => leaveReview(e)} className='container-form-reviews'>
										<div className='contain-msg-review'>
											<label htmlFor="comment" className="">Agregar reseña</label>
											<div className="mt-1">
												<textarea
													rows={4}
													name="comment"
													id="comment"
													required
													value={comment}
													onChange={e=>onChange(e)}
													className='review-msg-input'
													defaultValue={''}
												/>
											</div>
										</div>
										<StarRating onChange={onChange} rating={rating}/>
										<button type="submit" className='add-cart-button'>Enviar</button>
									</form>
								)
							) : <></>}
						</div>
					</div>
				<div className='container-related-products'>
					<div className='related-products-title'>
						<h3>Productos Relacionados</h3>
					</div>
					<div className='card-related'>
						{related_products && related_products.map(related => (
							<div key={related.id} className='related-products'>
								<RelatedCard id={related.id} name={related.name} price={related.final_price} rating={related.average_rating} image={related.main_photo} />
							</div>
						))}
					</div>
				</div>
			</div>
		</Layout>
	)
};

const mapStateToProps = state => ({
		product: state.Products.product,
		related_products: state.Products.related_products,
		items: state.Cart.items,
		isAuthenticated: state.Auth.isAuthenticated,
		review: state.Reviews.review,
		reviews: state.Reviews.reviews,
})

export default connect(mapStateToProps, {
		get_product,
		get_related_products,
		get_items,
		get_total,
		get_item_total,
		add_item,
		add_wishlist_item,
		get_wishlist_items,
		get_wishlist_item_total,
		remove_wishlist_item,
		get_reviews,
		get_review,
		create_review,
		update_review,
		delete_review,
		filter_reviews,
}) (ProductDetail)