//React
import React from 'react';
//Components
import { Link } from 'react-router-dom';
//Styles
import '../../styles/components.css';
//Icons
import { FaWhatsapp } from "react-icons/fa";


export const WhatsAppButton = () => {
	return (
		<Link to='https://wa.me/+543574454270?text=Buenos%20días,%20La%20Joyita,%20quiero%20información%20de%20los%20productos.' className='whatsapp-button' style={{textDecoration: 'none'}}>
			<div className='whatsapp-icon'><FaWhatsapp /></div>
			<p>Estoy en línea ¡Escribime!</p>
		</Link>
	);
};




