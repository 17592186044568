// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';	
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';

// Database Cart
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAIL = 'ADD_ITEM_FAIL';
export const GET_TOTAL_SUCCESS = 'GET_TOTAL_SUCCESS';
export const GET_TOTAL_FAIL = 'GET_TOTAL_FAIL';
export const GET_ITEM_TOTAL_SUCCESS = 'GET_ITEM_TOTAL_SUCCESS';
export const GET_ITEM_TOTAL_FAIL = 'GET_ITEM_TOTAL_FAIL';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAIL = 'GET_ITEMS_FAIL';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAIL = 'UPDATE_ITEM_FAIL';
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_FAIL = 'REMOVE_ITEM_FAIL';
export const EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS';
export const EMPTY_CART_FAIL = 'EMPTY_CART_FAIL';
export const SYNCH_CART_SUCCESS = 'SYNCH_CART_SUCCESS';
export const SYNCH_CART_FAIL = 'SYNCH_CART_FAIL';

// Locale Cart
export const ADD_ITEM = 'ADD_ITEM';
export const GET_TOTAL = 'GET_TOTAL';
export const GET_ITEM_TOTAL = 'GET_ITEM_TOTAL';
export const GET_ITEMS = 'GET_ITEMS';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const EMPTY_CART = 'EMPTY_CART';

// Categories
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

// Coupons
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAIL = 'GET_COUPON_FAIL';

// Dates
export const CREATE_DATES_SUCCESS = 'CREATE_DATES_SUCCESS';
export const CREATE_DATES_FAIL = 'CREATE_DATES_FAIL';

// Orders
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAIL = 'GET_ORDER_DETAIL_FAIL';

// Payment
export const SET_PREFERENCE_LOADING = 'SET_PREFERENCE_LOADING';
export const REMOVE_PREFERENCE_LOADING = 'REMOVE_PREFERENCE_LOADING';
export const CREATE_PREFERENCE_SUCCESS = 'CREATE_PREFERENCE_SUCCESS';
export const CREATE_PREFERENCE_FAIL = 'CREATE_PREFERENCE_FAIL';
export const GET_PAYMENT_TOTAL_SUCCESS = 'GET_PAYMENT_TOTAL_SUCCESS';
export const GET_PAYMENT_TOTAL_FAIL = 'GET_PAYMENT_TOTAL_FAIL';
export const SET_PAYMENT_LOADING = 'SET_PAYMENT_LOADING';
export const REMOVE_PAYMENT_LOADING = 'REMOVE_PAYMENT_LOADING';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';
export const RESET_PAYMENT_INFO = 'RESET_PAYMENT_INFO';

// Products
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const GET_PRODUCTS_BY_ARRIVAL_SUCCESS = 'GET_PRODUCTS_BY_ARRIVAL_SUCCESS';
export const GET_PRODUCTS_BY_ARRIVAL_FAIL = 'GET_PRODUCTS_BY_ARRIVAL_FAIL';
export const GET_PRODUCTS_BY_SOLD_SUCCESS = 'GET_PRODUCTS_BY_SOLD_SUCCESS';
export const GET_PRODUCTS_BY_SOLD_FAIL = 'GET_PRODUCTS_BY_SOLD_FAIL';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAIL = 'SEARCH_PRODUCTS_FAIL';
export const RELATED_PRODUCTS_SUCCESS = 'RELATED_PRODUCTS_SUCCESS';
export const RELATED_PRODUCTS_FAIL = 'RELATED_PRODUCTS_FAIL';
export const FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS';
export const FILTER_PRODUCTS_FAIL = 'FILTER_PRODUCTS_FAIL';
export const CATEGORY_PRODUCTS_SUCCESS ='CATEGORY_PRODUCTS_SUCCESS';
export const CATEGORY_PRODUCTS_FAIL ='CATEGORY_PRODUCTS_FAIL';
export const HIGHLIGHT_PRODUCTS_SUCCESS = 'HIGHLIGHT_PRODUCTS_SUCCESS';
export const HIGHLIGHT_PRODUCTS_FAIL = 'HIGHLIGHT_PRODUCTS_FAIL';
export const GET_PROMOS_SUCCESS = 'GET_PROMOS_SUCCESS';
export const GET_PROMOS_FAIL = 'GET_PROMOS_FAIL';

// Profile
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';
export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';
export const REMOVE_PROFILE_LOADING = 'REMOVE_PROFILE_LOADING';

// Reviews
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_FAIL = 'GET_REVIEW_FAIL';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL';
export const FILTER_REVIEWS_SUCCESS = 'FILTER_REVIEWS_SUCCESS';
export const FILTER_REVIEWS_FAIL = 'FILTER_REVIEWS_FAIL';

// Shipping
export const GET_SHIPPING_OPTIONS_SUCCESS = 'GET_SHIPPING_OPTIONS_SUCCESS';
export const GET_SHIPPING_OPTIONS_FAIL = 'GET_SHIPPING_OPTIONS_FAIL';

// Wishlist
export const GET_WISHLIST_ITEMS_SUCCESS = 'GET_WISHLIST_ITEMS_SUCCESS';
export const GET_WISHLIST_ITEMS_FAIL = 'GET_WISHLIST_ITEMS_FAIL';
export const ADD_WISHLIST_ITEM_SUCCESS = 'ADD_WISHLIST_ITEM_SUCCESS';
export const ADD_WISHLIST_ITEM_FAIL = 'ADD_WISHLIST_ITEM_FAIL';
export const GET_WISHLIST_ITEM_TOTAL_SUCCESS = 'GET_WISHLIST_ITEM_TOTAL_SUCCESS';
export const GET_WISHLIST_ITEM_TOTAL_FAIL = 'GET_WISHLIST_ITEM_TOTAL_FAIL';
export const REMOVE_WISHLIST_ITEM_SUCCESS = 'REMOVE_WISHLIST_ITEM_SUCCESS';
export const REMOVE_WISHLIST_ITEM_FAIL = 'REMOVE_WISHLIST_ITEM_FAIL';
export const CLEAR_WISHLIST = 'CLEAR_WISHLIST';